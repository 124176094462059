<template>
  <div>
    <!-- editar -->
    <CRow v-if="bells != null">
    <CCol sm="12">
        <button
        type="button"
        class="btn-sm btn-info float-right"
        @click="mtd_back_to_list"
        >
        <i class="fas fa-arrow-left"></i>&nbsp;Regresar
        </button>
    </CCol>
    <br /><br />
    <CCol sm="12">
        <CCard>
        <CCardHeader>
            <strong> PROOVEDOR</strong>
        </CCardHeader>
        <CCardBody>
            <CRow>
            <CCol lg="2" md="2" sm="12" xs="12">
              <input type="hidden" v-model="header.id_provider"/>
                <CInput
                label="Documento del provedor"
                placeholder="12345678"
                v-model="header.document"
                v-on:keyup.enter="mtd_search_provider"
                />
            </CCol>
            <CCol lg="6" md="6" sm="12" xs="12">
                <CInput
                label="Nombre del provedor"
                placeholder="Nombre"
                v-model="header.name"
                :disabled="true"
                />
            </CCol>
            <CCol lg="2" md="2" sm="12" xs="12">
                <CInput
                label="Zona"
                placeholder="Zona"
                v-model="header.zone"
                :disabled="true"
                />
            </CCol>
            </CRow>
        </CCardBody>
        </CCard>
        <CCard>
        <CCardHeader>
            <strong> DETALLE DE ACOPIO</strong>
        </CCardHeader>
        <CCardBody>
            <CRow>
            <CCol lg="2" md="2" sm="6" xs="12">
                <input type="hidden" v-model="item_detail.id_detail_provider"/>
                <CInput
                label="Ticket"
                placeholder="Ticket"
                v-model="item_detail.ticket"
                />
            </CCol>
            <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                label="Humedad"
                placeholder="Humedad"
                v-model="item_detail.humedad"
                @blur="mtd_out_focus"
                v-on:keypress="isNumber($event)"
                />
            </CCol>
            <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                label="Rendimiento Fisico(%)"
                placeholder="Rendimiento Fisico(%)"
                v-model="item_detail.rendimiento"
                @blur="mtd_out_focus"
                v-on:keypress="isNumber($event)"
                />
            </CCol>
            <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                label="Taza"
                placeholder="Taza"
                v-model="item_detail.taza"
                @blur="mtd_out_focus"
                v-on:keypress="isNumber($event)"
                />
            </CCol>
            <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                label="Núm. sacos"
                placeholder="Núm. sacos"
                v-model="item_detail.sacos"
                @blur="mtd_out_focus"
                v-on:keypress="isNumber($event)"
                />
            </CCol>
            <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                label="Peso bruto"
                placeholder="Peso bruto"
                v-model="item_detail.peso_bruto"
                @blur="mtd_out_focus"
                v-on:keypress="isNumber($event)"
                />
            </CCol>
            <!-- calculados -->
            <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                label="Descuento x humedad"
                placeholder="Descuento x humedad"
                :value="cp_descuentoxhumedad"
                :disabled="true"
                />
            </CCol>
            <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                label="Tara sacos"
                placeholder="Tara sacos"
                :value="cp_tara"
                :disabled="true"
                />
            </CCol>
            <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                label="Peso neto(kg)"
                placeholder="Peso neto(kg)"
                :value="cp_pesoneto"
                :disabled="true"
                />
            </CCol>
            <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                label="N° QQ"
                placeholder="N° QQ"
                :value="cp_qq"
                :disabled="true"
                />
            </CCol>
            <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                label="Precio QQ"
                placeholder="Precio QQ"
                v-model="item_detail.precio_qq"
                @blur="mtd_out_focus"
                v-on:keypress="isNumber($event)"
                />
            </CCol>
            <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                label="Precio uni."
                placeholder="Precio uni."
                :value="cp_uni"
                :disabled="true"
                />
            </CCol>
            <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                label="Total Liq."
                placeholder="Total liq."
                :value="cp_liq"
                :disabled="true"
                />
            </CCol>
            <CCol lg="6" md="6" sm="12" xs="12">
                <CInput
                label="Detalle"
                placeholder="Detalle"
                v-model="item_detail.detail"
                />
            </CCol>
            <CCol lg="2" md="2" sm="6" xs="12">
                <label for="">&nbsp;</label>
                <br />
                <CButton
                label=""
                color="info"
                v-c-tooltip.hover="{
                    content: 'Agregar item',
                }"
                v-if="action.add==true"
                :disabled="cp_button"
                @click="mtd_add_item"
                >
                <i class="fa fa-plus"> </i> Agregar
                </CButton>
            </CCol>
            </CRow>
            <br />
            <CRow v-if="action.add==false">
              <CCol lg="12" md="12" sm="12" xs="12" class="text-center">
                <CButton
                size="lg"
                color="danger"
                v-c-tooltip.hover="{
                    content: 'Cancelar',
                }"
                @click="mtd_cancelar_form"
                >
                <i class="fas fa-times"></i> Cancelar
                </CButton>
                &nbsp;&nbsp;
                <CButton
                size="lg"
                color="info"
                v-c-tooltip.hover="{
                    content: 'Actualizar',
                }"
                @click="mtd_edit_form"
                >
                <i class="fas fa-save"></i> Actualizar
                </CButton>
              </CCol>
            </CRow>
            <br />
            <CRow v-if="details.length > 0">
            <CCol lg="12" md="12" sm="12" xs="12">
                <table class="table table-bordered table-striped responsive">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Detalle</th>
                    <th>Peso neto</th>
                    <th>Liquidación</th>
                    <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in details" :key="index">
                    <td>{{ item.id }}</td>
                    <td>{{ item.detail }}</td>
                    <td>{{ item.peso_neto }}</td>
                    <td>{{ item.total }}</td>
                    <td>
                        <CButton
                        size="sm"
                        color="danger"
                        v-c-tooltip.hover="{
                            content: 'Eliminar',
                        }"
                        @click="mtd_delitemcarrito(item.id)"
                        >
                        <i class="fas fa-trash"></i>
                        </CButton>
                        &nbsp;&nbsp;
                        <CButton
                          size="sm"
                          color="success"
                          v-c-tooltip.hover="{
                            content: 'Editar',
                          }"
                          v-if="item.id>0"
                          @click="mtd_showdetail(item)"
                        >
                          <i class="fas fa-edit"></i>
                        </CButton>
                    </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                    <td colspan="2" class="text-right">
                        <strong>TOTALES:</strong>
                    </td>
                    <td>{{ cp_total_neto }}</td>
                    <td>{{ cp_total_liq }}</td>
                    </tr>
                </tfoot>
                </table>
            </CCol>

            <CCol class="text-center" lg="12" md="12" sm="12" xs="12">
                <!-- <CButton
                size="lg"
                color="danger"
                v-c-tooltip.hover="{
                    content: 'Vaciar carrito',
                }"
                @click="mtd_vaciar_carrito"
                >
                <i class="fas fa-trash"></i> Vaciar carrito
                </CButton> -->
                &nbsp;&nbsp;
                <CButton
                size="lg"
                color="info"
                v-c-tooltip.hover="{
                    content: 'Guardar',
                }"
                v-if="action.saveCar==true"
                @click="mtd_save_acopio"
                >
                <i class="fas fa-save"></i> Guardar Carrito
                </CButton>
            </CCol>
            </CRow>
        </CCardBody>
        </CCard>
    </CCol>
    </CRow>
    <CRow v-else>
    <CCol col="12">
        <CCard>
        <CCardHeader> CAMPAÑA NO INICIADA </CCardHeader>
        <CCardBody>
            COMUNIQUESE CON EL SUPERADMINISTRADOR PARA INICIAR UNA CAMPAÑA Y
            PODER CONTINUAR.
        </CCardBody>
        </CCard>
    </CCol>
    </CRow>
    <!-- modal delete -->
    <cModalDelete
      title="Borrar Acopio"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>
  </div>
</template>

<script>
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  components: { CTableWrapper, cModalDelete },
  data() {
    return {
      prefix: "provideracopio",
      bells: null,
      header: {
        id_provider: "",
        supplier_id: "",
        document: "",
        name: "",
        zone: "",
      },
      action:{
        add: true,
        saveCar: false,
      },
      details: [],
      item_detail: {
        id_detail_provider: "",
        provider_acopio_id: "",
        ticket: "",
        humedad: "",
        rendimiento: "",
        taza: "",
        sacos: "",
        peso_bruto: "",
        dsctxh: "",
        tara_sacos: "",
        peso_neto: "", // truncar
        neto_num_qq: "", // truncar
        precio_qq: "",
        precio_uni: "", // truncar 5
        total: "",
        detail: "",
      },
      modal_delete: {
        boo: false,
        item: [],
      },
    };
  },
  computed: {
    cp_descuentoxhumedad: function () {
      let bruto =
        this.item_detail.peso_bruto > 0
          ? parseFloat(this.item_detail.peso_bruto).toFixed(2)
          : 0.0;
      let hume =
        this.item_detail.humedad > 0
          ? parseFloat(this.item_detail.humedad).toFixed(2)
          : 0.0;
      let op1 = parseFloat(bruto / 55.2).toFixed(2);
      let op2 = parseFloat(hume - 12);
      let res = parseFloat(op1 * op2 * 0.63).toFixed(2);
      this.item_detail.dsctxh = res;
      return res;
    },
    cp_tara: function () {
      let sacos =
        this.item_detail.sacos > 0
          ? parseFloat(this.item_detail.sacos).toFixed(2)
          : 0.0;
      let res = parseFloat(sacos * 0.25).toFixed(2);
      this.item_detail.tara_sacos = res;
      return res;
    },
    cp_pesoneto: function () {
      let bruto =
        this.item_detail.peso_bruto > 0
          ? parseFloat(this.item_detail.peso_bruto).toFixed(2)
          : 0.0;
      let humedad =
        this.item_detail.dsctxh > 0
          ? parseFloat(this.item_detail.dsctxh).toFixed(2)
          : 0.0;
      let tara =
        this.item_detail.tara_sacos > 0
          ? parseFloat(this.item_detail.tara_sacos).toFixed(2)
          : 0.0;
      let res = parseFloat(bruto - humedad - tara).toString();
      if (res <= 0) {
        this.peso_neto = 0;
        return 0;
      }
      let ar = res.split(".");
      let new_decimal = ar[1].substring(0, 2);
      let new_res = ar[0] + "." + new_decimal;
      this.item_detail.peso_neto = parseFloat(new_res).toFixed(2);
      return this.item_detail.peso_neto;
    },
    cp_qq: function () {
      let neto =
        this.item_detail.peso_neto > 0
          ? parseFloat(this.item_detail.peso_neto).toFixed(2)
          : 0.0;
      let res = parseFloat(neto / 55.2).toString();
      if (res <= 0) {
        this.peso_neto = 0;
        return 0;
      }
      let ar = res.split(".");
      let new_decimal = ar[1].substring(0, 2);
      let new_res = ar[0] + "." + new_decimal;
      this.item_detail.neto_num_qq = parseFloat(new_res).toFixed(2);
      return this.item_detail.neto_num_qq;
    },
    cp_liq: function () {
      let precio_qq =
        this.item_detail.precio_qq > 0
          ? parseFloat(this.item_detail.precio_qq).toFixed(2)
          : 0.0;
      let num_qq =
        this.item_detail.neto_num_qq > 0
          ? parseFloat(this.item_detail.neto_num_qq).toFixed(2)
          : 0.0;
      this.item_detail.total = parseFloat(precio_qq * num_qq).toFixed(2);
      return this.item_detail.total;
    },
    cp_uni: function () {
      let total =
        this.item_detail.total > 0
          ? parseFloat(this.item_detail.total).toFixed(2)
          : 0.0;
      let neto =
        this.item_detail.peso_neto > 0
          ? parseFloat(this.item_detail.peso_neto).toFixed(2)
          : 0.0;
      this.item_detail.precio_uni =
        neto > 0 ? parseFloat(total / neto).toFixed(2) : 0;
      return this.item_detail.precio_uni;
    },
    cp_button() {
      return this.item_detail.detail.length > 0? false:true;
    },
    cp_total_neto: function () {
      let tot = 0;
      if (this.details.length > 0) {
        this.details.forEach((element) => {
          tot += parseFloat(element.peso_neto,2);
        });
        return parseFloat(tot);
      } else {
        return 0;
      }
    },
    cp_total_liq: function () {
      let tot = 0;
      if (this.details.length > 0) {
        this.details.forEach((element) => {
          tot += parseFloat(element.total,2);
        });
        return parseFloat(tot);
      } else {
        return 0;
      }
    },
  },
  created() {
    this.mtd_getdata();
    this.mtd_load_data();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.bells = response.bells;
        })
        .catch((errors) => { 
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_load_data: function() {
      let idProvider_detail = this.$route.params.id;

      this.get({
        url: this.$store.getters.get__url+"/"+this.prefix+"/"+idProvider_detail+"/LoaData",
        token: this.$store.getters.get__token
      })
      .then((response) =>{
        this.header.id_provider = response.id_provider;
        this.header.document = response.document;
        this.mtd_search_provider();
        this.details = response.detalle;
      })
      .catch((errors) => { 
        // this.errorsBackend = errors;
        // this.$emit("error", this.event);
      })
    },
    mtd_back_to_list: function () {
      window.open("/#/proccess/provideracopio", "_top");
    },
    mtd_search_provider: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.header.document +
          "/search_provider",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response) {
            bus.$emit("alert", {
              color: "success",
              message: "DOCUMENTO ENCONTRADO",
            });
            this.header.supplier_id = response.id;
            this.header.name = response.last_name + " " + response.name;
            this.header.zone = response.zone;
          } else {
            bus.$emit("alert", {
              color: "warning",
              message: "EL DOCUMENTO NO EXISTE",
            });
            this.header.document = "";
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_out_focus: function () {
      this.item_detail.humedad =
        this.item_detail.humedad > 0
          ? parseFloat(this.item_detail.humedad).toFixed(2)
          : 0.0;
      this.item_detail.rendimiento =
        this.item_detail.rendimiento > 0
          ? parseFloat(this.item_detail.rendimiento).toFixed(2)
          : 0.0;
      this.item_detail.peso_bruto =
        this.item_detail.peso_bruto > 0
          ? parseFloat(this.item_detail.peso_bruto).toFixed(2)
          : 0.0;
      this.item_detail.sacos =
        this.item_detail.sacos > 0
          ? parseFloat(this.item_detail.sacos).toFixed(2)
          : 0.0;
      this.item_detail.taza =
        this.item_detail.taza > 0
          ? parseFloat(this.item_detail.taza).toFixed(2)
          : 0.0;
      this.item_detail.precio_qq =
        this.item_detail.precio_qq > 0
          ? parseFloat(this.item_detail.precio_qq).toFixed(2)
          : 0.0;
    },
    mtd_add_item: function () {
      this.details.push(this.item_detail);
      this.item_detail = {
        id_detail_provider: "",
        provider_acopio_id: "",
        ticket: "",
        humedad: "",
        rendimiento: "",
        taza: "",
        sacos: "",
        peso_bruto: "",
        dsctxh: "",
        tara_sacos: "",
        peso_neto: "", // truncar
        neto_num_qq: "", // truncar
        precio_qq: "",
        precio_uni: "", // truncar 5
        total: "",
        detail: "",
      };

      this.action.saveCar = true;
    },
    mtd_delitemcarrito: function (id) {
      let item = {'Id':id};
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
      // let temp = [];
      // this.details.forEach((element, index) => {
      //   if (index !== pos) {
      //     temp.push(element);
      //   }
      // });
      // this.details = temp;
    },
    mtd_showdetail: function(pos) {
      this.item_detail.id_detail_provider = pos.id,
      this.item_detail.ticket = pos.ticket;
      this.item_detail.humedad = pos.humedad;
      this.item_detail.rendimiento = pos.rendimiento;
      this.item_detail.taza = pos.taza;
      this.item_detail.sacos = pos.num_saco;
      this.item_detail.peso_bruto = pos.peso_bruto;
      this.item_detail.precio_qq = pos.precio_qq;
      this.item_detail.detail = pos.detalle;

      this.action.add = false;
    },
    mtd_cancelar_form: function(){
      this.item_detail = {
        id_detail_provider: "",
        provider_acopio_id: "",
        ticket: "",
        humedad: "",
        rendimiento: "",
        taza: "",
        sacos: "",
        peso_bruto: "",
        dsctxh: "",
        tara_sacos: "",
        peso_neto: "", // truncar
        neto_num_qq: "", // truncar
        precio_qq: "",
        precio_uni: "", // truncar 5
        total: "",
        detail: "",
      };

      this.action.add = true;
    },
    mtd_edit_form: function() {
      this.post({
        url: this.$store.getters.get__url+"/"+this.prefix+"/edit",
        token: this.$store.getters.get__token,
        params: this.item_detail
      })
      .then((response) =>{
        if(response.acopio){
          bus.$emit("alert", {
            color: "success",
            message: "ACTUALIZADO CORRECTAMENTE",
          });
          this.mtd_cancelar_form();
          this.action.add = true;
          this.mtd_load_data();
          window.open("/assets/acopio/" + response.uuid + ".pdf", "_blank");

        }else{
          bus.$emit("alert", {
            color: "danger",
            message: "ERROR AL ACTUALIZAR",
          });
        }
      })
      .catch((errors) =>{
        // this.errorsBackend = errors;
        // this.$emit("error", this.event);
      });
    },
    mtd_vaciar_carrito: function () {
      this.details = [];
    },
    mtd_save_acopio: function(){
      console.log(this.details);
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/car",
        token: this.$store.getters.get__token,
        params: {
          header: this.header,
          details: this.details,
          bell_id: this.bells.id,
        },
      })
        .then((response) => {
          console.log(response);

          if (response.state == 0) {
            this.item_detail = {
              id_detail_provider: "",
              provider_acopio_id: "",
              ticket: "",
              humedad: "",
              rendimiento: "",
              taza: "",
              sacos: "",
              peso_bruto: "",
              dsctxh: "",
              tara_sacos: "",
              peso_neto: "", // truncar
              neto_num_qq: "", // truncar
              precio_qq: "",
              precio_uni: "", // truncar 5
              total: "",
              detail: "",
            };
            bus.$emit("alert", {
              color: "success",
              message: "ACOPIO GUARDADO CORRECTAMENTE",
            });
            window.open("/assets/acopio/" + response.uuid + ".pdf", "_blank");
            this.mtd_back_to_list();
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.exception,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },

    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
      .then((response) => {
        if (response.state == 0) {
          this.modal_delete = {
            boo: false,
            item: [],
          };
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });

          this.mtd_load_data();
          this.mtd_back_to_list();

        } else {
          bus.$emit("alert", {
            color: "danger",
            message: response.message,
          });
        }
      })
      .catch((errors) => {
        // this.errorsBackend = errors;
        // this.$emit("error", this.event);
      });
    },
  },
};
</script>